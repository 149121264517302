import React from "react";
import { Badge } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import useSWR from "swr";

import { ArticleRevisionType, IArticleDTO, IArticleRevisionDTO } from "@generatedCode/pbd-core/pbd-core-api";

import { DateTimeLuxonHelpers } from "../../../Helpers/DateTimeLuxonHelpers";
import ArticleService from "../../../pbdServices/services/Articles/articleService";
import { useAPIServices } from "../../../pbdServices/services/service-context";
import { CustomBpmnViewer } from "../../articles/bpmn/bpmnViewer";
import { prepareArticleForPrint } from "../../articles/helpers/articleContentHelper";
import FlowEditor from "../../shared/components/FlowEditor/flowEditor";
import { qmBaseIcons } from "../../shared/components/icons/qmBaseIcons";
import FormattedUserInput from "../../shared/components/text/formattedUserInput";

interface IProps {
  article: IArticleDTO;
  revision?: IArticleRevisionDTO;
  showReviewers?: boolean;
}

function ArticlePrintout(props: IProps) {
  const { article, revision, showReviewers } = props;
  const { t } = useTranslation();
  const { articleRevisionService } = useAPIServices();

  const { data: approvals } = useSWR(
    revision?.approvals
      ? [`/api/articleRevisions/${revision.id}/approvers`, revision.approvals.filterMap((x) => x.approverId)]
      : null,
    () => {
      if (!revision?.approvals) return null;
      return articleRevisionService.mapApprovalsToTenants(revision.approvals);
    },
  );

  const content = React.useMemo(() => {
    return prepareArticleForPrint(revision?.content ?? article.content ?? "");
  }, [article.content, revision?.content]);

  const articleType = React.useMemo(
    () => ArticleService.getArticleTypeWithContent({ title: article.title, content: content }),
    [article.title, content],
  );

  const bpmnContent = React.useMemo(() => {
    if (articleType.kind != ArticleRevisionType.Bpmn) {
      return undefined;
    }
    return ArticleService.deserializeBpmn(revision?.content);
  }, [articleType.kind, revision?.content]);

  return (
    <>
      <h2>{article.title}</h2>
      <h4>{(!revision?.isPublished || !article.isPublished) && <Badge bg="danger">Unpublished</Badge>}</h4>

      {article.isPublished && revision?.isPublished && (
        <>
          <span>{t("Version")}: </span>
          <span aria-label="articleVersion">{revision.version ?? article.version}</span> <span>{t("Published")}: </span>
          {DateTimeLuxonHelpers.convertUtcToDate(revision.publishedAt ?? article.publishedAt)}{" "}
        </>
      )}
      <span>{t("Responsible")}: </span>
      <span>{article.responsible?.fullName}</span>
      <hr />
      {articleType.kind == ArticleRevisionType.Html && <FormattedUserInput ariaLabel="mainContent" content={content} />}
      {articleType.kind == ArticleRevisionType.Flow && (
        <div>
          <FlowEditor value={articleType.flow} />
        </div>
      )}
      {articleType.kind == ArticleRevisionType.Bpmn && bpmnContent?.content && (
        <CustomBpmnViewer diagramXML={bpmnContent.content} printMode />
      )}
      {showReviewers && (
        <>
          <h5>{t("Approvers")}: </h5>
          {approvals == null && <span>{t("No approver selected")}</span>}
          <ul>
            {approvals?.map((x) => (
              <li key={x.id}>
                {x.isApproved ? (
                  <qmBaseIcons.Check title={t("Approved")} />
                ) : (
                  <qmBaseIcons.NotApproved title={t("Not approved")} />
                )}{" "}
                {x.approvedAt && DateTimeLuxonHelpers.convertUtcToDateTime(x.approvedAt)} - {x.approver.fullName} #
                {x.approverId}
              </li>
            ))}
          </ul>
        </>
      )}
    </>
  );
}

export default ArticlePrintout;
